$accent: #9f3535;
$font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

// Data management
@import "~@progress/kendo-theme-bootstrap/scss/grid";

// Editors
@import "~@progress/kendo-theme-bootstrap/scss/editor";
@import "~@progress/kendo-theme-bootstrap/scss/upload"; // upload depends on editor

.k-widget .ui.grid {
    // semantic grids won't take 100% width without when inside kendo components
    flex-grow: 1;
}
